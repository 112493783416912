import React from "react"
import { Link } from "gatsby"
import "../assets/css/partners.css"
// import Logo from "../images/logo.png"
import Footer from '../components/footer';
import NewsLatter from '../components/newsletter';
import ShoppingContainer from '../components/shoppingcontainer';

// import appStoreIcon from "../images/app-store-icon.png"
// import googlePlayIcon from "../images/google-pay-icon.png"
// import fbIcon from "../images/fb-icon.svg"
// import twitterIcon from "../images/twitter.svg"
// import instacon from "../images/insta-icon.svg"


// import video from "../images/video-icon.svg"

import lineImage from "../images/line2.png"

// import mailIcon from "../images/mail-icon.svg"
// import callIcon from "../images/call-icon.svg"
// import livechat from "../images/live-chat-icon.svg"

import Layout from "../components/layout"




// import mapIcon from "../images/map.svg"
// import mobileImg from "../images/mobile-img.png"

// import newslatter from "../images/newslatter.svg"
import handIcon from "../images/hand.svg"
import chatbottom from "../images/chat-bottom-icon.svg"
import chatProfile from "../images/chat-profile.png"
import closeChat from "../images/close-icon.svg"

import partnersBanner from "../images/partners/partner-banner.svg"

import icon1 from "../images/partners/icon-1.svg"
import icon2 from "../images/partners/icon-2.svg"
import icon3 from "../images/partners/icon-3.svg"
import icon4 from "../images/partners/icon-4.svg"
import icon5 from "../images/partners/icon-5.svg"

import pgicon1 from "../images/partners/pg-icon-1.svg"
import pgicon2 from "../images/partners/pg-icon-2.svg"
import pgicon3 from "../images/partners/pg-icon-3.svg"
import pgicon4 from "../images/partners/pg-icon-4.svg"

import digitalimg from "../images/partners/digital.svg"
import digitalimg2 from "../images/partners/digital-2.svg"

import SEO from "../components/seo"

const BenefitsPage = (props) => (
  <Layout rest={props} layoutType="main">
    <SEO title="Partners" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
    <div className="chat-content" style={{ display: 'none' }}>
      <div className="chat-content-circle">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
          <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
            <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
              <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
              <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
              <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
              <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
            </g>
          </g>
        </svg>
      </div>
      <div className="chat-box">

        <div className="chatbox-inner">
          <div className="chat-profile">
            <img className="" src={chatProfile} alt="" />
          </div>
          <span className="close-chat"><img className="" src={closeChat} alt="" /></span>
          <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
        </div>
        <img className="chat-bottom" src={chatbottom} alt="" />
      </div>

    </div>

    {/* --------------banner--------- */}
    <section className="banner partners-banner">
      <div className="banner-inner">  
        <img src={partnersBanner} alt="" className="partners-banner-img" />
        <div className="container">
          <h1 className="partners-title">Your business, accelerated</h1>
          <h5 className="partners-sub-title">Drive customer loyalty and improve your bottom line with servicecontract.com, the industry's only all-digital s3ervicecontract solution.</h5>
          <button className="btn partner-banner-btn partner-btn">PARTNER WITH US</button>
        </div>
      </div>
    </section>

    {/* --------------- process-work-container--------- */}

    <section className="process-work-container extra-benefits benifitsSection break-down-section">
      <div className="container">
        <h3 className="title">Car breakdowns happen.</h3>
        <p className="subtitle">But with servicecontract.com, they don’t have to be a hassle. 
                                We provide the tools you need to sell high-quality auto service 
                                plans to your own customers, delivering peace of mind for them 
                                and a competitive advantage for you.</p>
      
        <div className="work-content">
          <div className="card">
            <div className="header">
              <span> <img src={icon1} alt="Logo" /></span>
            </div>
            <p>Create a recurring revenue stream</p>
          </div>
          <div className="card">
            <div className="header">
              <span> <img src={icon2} alt="Logo" /></span>
            </div>
            <p>Improve customer satisfaction and retention</p>
          </div>
          <div className="card">
            <div className="header">
              <span> <img src={icon3} alt="Logo" /></span>
            </div>
            <p>Innovate without upfront investments</p>
          </div>
          <div className="card">
            <div className="header">
              <span> <img src={icon4} alt="Logo" /></span>
            </div>
            <p>Customize coverage around your business’ needs</p>
          </div>
          <div className="card last">
            <div className="header">
              <span> <img src={icon5} alt="Logo" /></span>
            </div>
            <p>Gain data-driven insights into customer behavior</p>
          </div>
        </div>
        <div className="slider-point">
          <span className="active"></span>
          <span></span>
          <span></span>
        </div>
        <div className="btn-container">
          {/* <button className="btn get-price-btn">Get Your Price</button> */}
          <Link className="btn primary get-price-btn partner-btn" to="/vehicleInfo">SCHEDULE A DEMO</Link>
        </div>
      </div>

    </section>

    <section className="product-growth-container">
      <div className="container">
        <h3 className="title">A product poised for growth</h3>
        <p className="subtitle">Aging cars, more vehicles on the road and consumer demand will create significantly more 
                                opportunity for auto service plan providers by 2024, according to Colonnade Advisors’ recent VSC 
                                Industry Commentary. The advisory firm recognized Servicecontract as one of the firms ready to 
                                shake up the industry.</p>
          <div className="product-growth-content">
              <div className="card">
                <div className="inner-card">
                    <div className="header">
                        <span><img src={pgicon1} alt="Logo" /></span>
                    </div>
                    <div className="body">
                      <p>Average<br/> length of <br/>vehicle <br/>ownership has <br/>increased <br/><label>50<sup>%</sup></label><br/>since 2009</p>
                    </div>
                </div>
              </div>
              <div className="card">
                <div className="inner-card">
                    <div className="header">
                        <span><img src={pgicon2} alt="Logo" /></span>
                    </div>
                    <div className="body">
                      <p><label>77<sup>%</sup></label><br/>of all cars <br/>inspected<br/> need<br/> service<br/> or<br/> repairs</p>
                    </div>
                </div>
              </div>
              <div className="card">
                <div className="inner-card">
                    <div className="header">
                        <span><img src={pgicon3} alt="Logo" /></span>
                    </div>
                    <div className="body">
                      <p><label>6<span>IN</span>10</label><br/>Americans<br/> can't afford a <br/><label><sup>$</sup>500</label><br/>car repair bill</p>
                    </div>
                </div>
              </div>
              <div className="card last">
                <div className="inner-card">
                    <div className="header">
                        <span><img src={pgicon4} alt="Logo" /></span>
                    </div>
                    <div className="body">
                      <p><label>113</label><br/>MILLION <br/>U.S. vehicles<br/> are more than <br/><label>11</label><br/>YEARS OLD</p>
                    </div>
                  </div>
              </div>
          </div>
          <div className="slider-point">
              <span className="active"></span>
              <span></span>
              <span></span>
          </div>

        <div className="btn-container">
          {/* <button className="btn get-price-btn">Get Your Price</button> */}
          <Link className="btn primary product-growth-btn partner-btn" to="/vehicleInfo">DOWNLOAD THE REPORT</Link>
        </div>
      </div>

    </section>

  <section className="digital-container">
    <div className="container">
        <div className="digital-content">
            <div className="left-1">
                <img src={digitalimg} alt="" />
            </div>
            <div className="right">
                <h3>All-in-one digital platform</h3>
                <p>As a strategic partner for car businesses, we’ve developed a complete solution that takes the guesswork out of selling service contracts. Use only the components you need, or unleash its full power — we just need your logo and your customer list.</p>
                <button className="btn partner-btn digital-btn">LEARN MORE</button>
            </div>
        </div>
        <div className="digital-content">
            <div className="left">
                <h3>All-in-one digital platform</h3>
                <p>As a strategic partner for car businesses, we’ve developed a complete solution that takes the guesswork out of selling extended service plans at scale. Use only the components you need, or unleash its full power — we just need your logo and your customer list.</p>
                <button className="btn partner-btn digital-btn">SCHEDULE A DEMO</button>
            </div>
            <div className="right-1">
                <img src={digitalimg2} alt="" />
            </div>
        </div>
    </div>
  </section>

    <ShoppingContainer className="bg"/>
    {/* <NewsLatter /> */}
    <div className="break-section">
      <img src={lineImage} alt="line" />
    </div>
    <Footer />
  </Layout>
)

export default BenefitsPage
